.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.vis,
.vis-overlay {
  position: fixed;
  left: 400px;
  top: 0px;
  height: 100%;
  width: calc(100% - 800px);
  overflow-y: auto;
}

.vis-overlay {
  background: #00000066;
  color: white;
  font-size: 24px;
  font-weight: 500;
  padding-top: 100px;
  text-align: center;
  text-transform: uppercase;
}

.vis-overlay-hidden {
  visibility: collapse;
}

.gene-list,
.metadata-table {
  position: fixed;
  top: 0px;
  padding: 20px;
  width: 400px;
  height: 100%;
  background: white;
  overflow-y: auto;
}

.gene-list {
  left: 0px;
  border-right: 1px solid #dadada;
}

.metadata-table {
  right: 0px;
  border-left: 1px solid #dadada;
}

.metadata-table table {
  width: 100%
}

td {
  border: 1px solid #dadada;
  min-width: 150px;
  padding: 5px;
}

.gene-list a:link, 
.gene-list a:visited,
.metadata-table a:link, 
.metadata-table a:visited {
  text-decoration: underline dotted #0072B2;
  color: #0072B2;
}

.sub-info {
  position: absolute;
  right: 20px;
}

.search-box {
  width: 100%;
  height: 30px;
  line-height: 30px;
  border: 1px solid #dadada;
}

.chr-button {
  padding: 6px;
  margin: 6px;
  margin-left: 0px;
  margin-top: 0px;
  /* border-radius: 3px; */
  border: 1px solid #dadada;
  background: white;
  display: inline-block;
  cursor: pointer;
}

.gene-button {
  width: 100%;
  height: 30px;
  line-height: 30px;
  background-color: white;
  border: 1px solid #dadada;
  cursor: pointer;
  margin-bottom: 3px;
  text-align: center;
}

.chr-button:hover,
.gene-button:hover {
  background-color: lightgray;
}